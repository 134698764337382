export const WEB_FORMS_QUERY = /* GraphQL */ `
    query webformsForm($formId: Int!) {
        webformsForm(form_id: $formId) {
            form_id
            name
            code
            description
            below_text
            success_text
            is_survey
            submit_button_text
            url_key
            meta_title
            meta_keywords
            meta_description
            fieldsets {
                fieldset_id
                name
                fields {
                    field_id
                    name
                    type
                    code
                    comment
                    type_attributes_serialized
                    is_required
                    validation_required_message
                    position
                    is_label_hidden
                }
            }
        }
    }
`
