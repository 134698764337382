import { RefObject } from 'react'

export const loadScript = (
    scripts: string[],
    elementRef: RefObject<HTMLDivElement>
) => {
    scripts?.map((url, index) => {
        if (url && !document.getElementById(`script-${index}`)) {
            const script = document.createElement('script')
            script.src = url
            script.async = true
            script.id = `script-${index}`
            elementRef?.current?.appendChild(script)
        }
    })
}
