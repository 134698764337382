import React, { RefObject } from 'react'
import { CarouselData } from '@components/RichContent/config'
import { SliderComponent } from '@components/RichContent/OwlCarousel/SliderComponent'
import { Maybe } from '@graphql-tools/utils'

type PropsType = {
    owlCarouselItems: CarouselData[]
    divRef?: Maybe<RefObject<HTMLDivElement>>
}

export const OwlCarouselComponent = (props: PropsType): JSX.Element => {
    return (
        <>
            {props.owlCarouselItems.map((owlCarouselItem, index) => (
                <SliderComponent
                    owlCarouselItem={owlCarouselItem}
                    key={index}
                    divRef={props.divRef}
                />
            ))}
        </>
    )
}

export default OwlCarouselComponent
