import React, { RefObject, useEffect, useState } from 'react'
import { CarouselData } from '@components/RichContent/config'
import { OwlCarouselComponent } from '@components/RichContent/OwlCarousel'
import { Maybe } from '@graphql-tools/utils'
import { MageMeWebform } from '@components/common/MageMeWebform'

type PropsType = {
    mageMeWebform: any[]
    owlCarouselItems: CarouselData[]
    divRef?: Maybe<RefObject<HTMLDivElement>>
}

export const DynamicComponent = (props: PropsType): JSX.Element | null => {
    const [RenderComponents, setRenderComponents] = useState<JSX.Element[]>([])

    useEffect(() => {
        const updatedRenderComponents: JSX.Element[] = []
        const uniqueFormIds = new Set<string>()

        if (props.owlCarouselItems?.length) {
            updatedRenderComponents.push(
                <OwlCarouselComponent
                    key="owlCarouselComponent"
                    owlCarouselItems={props.owlCarouselItems}
                    divRef={props.divRef}
                />
            )
        }
        if (props.mageMeWebform?.length) {
            props.mageMeWebform.forEach((formItem) => {
                if (formItem.formId && !uniqueFormIds.has(formItem.formId)) {
                    updatedRenderComponents.push(
                        <MageMeWebform
                            key={formItem.formId}
                            formID={formItem.formId}
                        /> // Use formId as key
                    )
                    uniqueFormIds.add(formItem.formId)
                }
            })
        }

        setRenderComponents(updatedRenderComponents)
    }, [props?.mageMeWebform, props?.owlCarouselItems])

    return (
        <div>
            {RenderComponents?.map((RenderComponent, index) => (
                <div key={index}>{RenderComponent}</div>
            ))}
        </div>
    )
}
