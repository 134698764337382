import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import formStyles from '@commonStyles/Forms.module.scss'
import { useWebForm } from './useWebForm'
import { useForm } from 'react-hook-form'
import { InputFieldSet } from '@pylot-data/pylotschema'
import { graphqlFetch } from '@pylot-data/graphqlFetch'
import { submitWebForm } from './SubmitWebFrom'
import { ToastType, useUI } from '@corratech/pylot-ui/context'
import { Button } from '@corratech/pylot-ui'
import MageMeWebformFieldSet from './MageMeWebformFieldSet'
type FormData = {
    [fieldId: string]: string
}

type SubmitWebFormResponse = {
    data: {
        webformsSubmitForm: {
            success: boolean
            result: string
            errors?: string
        }
    }
    errors?: { [key: string]: string }[]
}

export const MageMeWebform: React.FC<{
    formID: string
}> = ({ formID }) => {
    const { t } = useTranslation()

    const { webFormsData } = useWebForm(parseInt(formID))
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const { openToast } = useUI()

    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = async (data: FormData) => {
        const payload = {
            field: { ...data }
        }
        const jsonString = JSON.stringify(payload)

        setIsLoading(true)
        const response = (await graphqlFetch({
            query: submitWebForm,
            variables: {
                form_id: formID,
                formData: jsonString
            }
        })) as SubmitWebFormResponse
        setIsLoading(false)
        if (response?.errors) {
            openToast(
                t('Something went wrong. Please try again later'),
                ToastType.Danger
            )
            return
        }
        if (!response?.data?.webformsSubmitForm?.success) {
            const errors = response.data?.webformsSubmitForm?.errors
                ? JSON.parse(response.data?.webformsSubmitForm?.errors)
                : []
            let errorMessage
            if (Array.isArray(errors)) {
                const errorsJoined = errors
                    .map((element) => element.replace(':', ''))
                    .join(', ')
                errorMessage = `Please enter valid input in ${errorsJoined} `
            } else {
                errorMessage = `Something went wrong. Please try again later`
            }
            openToast(t(errorMessage), ToastType.Danger)
            return
        }

        if (response?.data?.webformsSubmitForm?.success) {
            setIsFormSubmitted(true)
        }
    }

    if (!webFormsData?.fieldsets?.length) {
        return null
    }

    return (
        <div className={formStyles.root}>
            {isFormSubmitted ? (
                <div className="contact-thank-you-message">
                    <h5>
                        {t(
                            'THANKS FOR TAKING THE TIME TO WRITE, YOUR MESSAGE WILL BE ROUTED TO THE APPROPRIATE PERSON AND WE WILL RESPOND AS QUICKLY AS POSSIBLE.'
                        )}
                    </h5>
                    <p>
                        {t(
                            'If you need urgent assistance, please contact our business office at the number above during standard business hours.'
                        )}
                    </p>
                </div>
            ) : (
                <div className="mage-form-container">
                    <h3>{t('LEAVE A MESSAGE:')}</h3>

                    <form
                        className="form-block"
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                    >
                        {webFormsData.fieldsets.map(
                            (fieldSet: InputFieldSet) => {
                                return (
                                    <MageMeWebformFieldSet
                                        key={fieldSet.fieldset_id}
                                        fieldSet={fieldSet}
                                        register={register}
                                        errors={errors}
                                    />
                                )
                            }
                        )}

                        <Button type="submit" loading={isLoading}>
                            {isLoading ? '' : t('Submit Message')}
                        </Button>
                    </form>
                </div>
            )}
        </div>
    )
}
