import React from 'react'
import { InputFieldSet, InputFields } from '@pylot-data/pylotschema'
import { UseFormRegister, FieldValues } from 'react-hook-form'
import s from './MageMeWebform.module.scss'
import { useTranslation } from 'next-i18next'
import { useAccountInformation } from '@corratech/pylot-account-information/src/hooks/use-account-information'
type InputTypeProps = {
    textarea: React.ReactElement
    select: React.ReactElement
    default: React.ReactElement
    [key: string]: React.ReactElement // Index signature
}
const MageMeWebformFieldSet: React.FC<{
    fieldSet: InputFieldSet
    register: UseFormRegister<FieldValues>
    errors: {
        [key: string]: any
    }
}> = ({ fieldSet, register, errors }) => {
    const { t } = useTranslation()
    const { getIsValidEmailAddress } = useAccountInformation()

    const parseOptions = (optionsString: string) => {
        try {
            const optionsArray = JSON.parse(optionsString)
                ?.options?.split('\r\n')
                .map((option: string) => option.replace(/^\^/, ''))
            return optionsArray ?? []
        } catch (error) {
            console.error('Error parsing options:', error)
            return []
        }
    }

    const getInputProps = (field: InputFields) => {
        if (!field.type) {
            return null
        }
        const commonProps = {
            id: field.code as string,
            placeholder: field.name as string,
            ...register(field.field_id.toString(), {
                required: !!field.is_required,
                ...(field.code === 'phone' && {
                    pattern: {
                        value: /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
                        message: 'Please enter a valid phone number.'
                    }
                }),
                validate: (() => {
                    if (field.type === 'email') {
                        return getIsValidEmailAddress
                    }
                })()
            }),
            className: 'border-2'
        }

        const inputTypeProps: InputTypeProps = {
            textarea: <textarea {...commonProps} />,
            select: (
                <select {...commonProps}>
                    {parseOptions(
                        field.type_attributes_serialized as string
                    )?.map((option: string) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            ),
            default: <input type={field.type} {...commonProps} />
        }

        return inputTypeProps[field.type] || inputTypeProps.default
    }

    return (
        <div className={s.container}>
            {fieldSet.fields.map((field: InputFields) => {
                return (
                    <div
                        className={`form-group ${field.code}`}
                        key={field.field_id}
                    >
                        <label htmlFor={field.code as string} className="block">
                            {t(field.name)}
                            {field.is_required ? <span>{t('*')}</span> : null}
                        </label>
                        {getInputProps(field)}
                        {errors?.[field.field_id] && (
                            <span className="error text-red block">
                                {errors?.[field.field_id].message.length
                                    ? errors?.[field.field_id].message
                                    : t('This field is required')}
                            </span>
                        )}
                    </div>
                )
            })}
        </div>
    )
}
export default MageMeWebformFieldSet
