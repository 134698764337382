import React, { RefObject, useEffect, useState } from 'react'
import { CarouselData } from '@components/RichContent/config'
import SlickSlider from 'react-slick'
import cn from 'classnames'
import { Maybe } from '@graphql-tools/utils'

type PropsType = {
    owlCarouselItem: CarouselData
    divRef?: Maybe<RefObject<HTMLDivElement>>
}
const settingsWithNoSwipe = {
    arrows: true,
    speed: 400,
    slidesToScroll: 1,
    slidesToShow: 3,
    draggable: true,
    infinite: false,
    dots: false,
    fade: false,
    prevArrow: <button type="button" className="slick-prev" />,
    nextArrow: <button type="button" className="slick-next" />,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        }
    ]
}

interface OriginalResponsive {
    [breakpoint: string]: {
        items: number
    }
}

interface ConvertedResponsive {
    breakpoint: number
    settings: {
        slidesToShow: number
    }
}

const convertResponsive = (
    originalResponsive: OriginalResponsive
): ConvertedResponsive[] => {
    return Object.keys(originalResponsive).map((breakpoint) => {
        const items = originalResponsive[breakpoint].items
        const convertedBreakpoint: ConvertedResponsive = {
            breakpoint:
                parseInt(breakpoint) +
                (parseInt(breakpoint) <= 992 ? 640 : 200),
            settings: {
                slidesToShow: items
            }
        }
        return convertedBreakpoint
    })
}

export const SliderComponent = (props: PropsType): JSX.Element | null => {
    const [settings, setSettings] = useState({
        ...settingsWithNoSwipe,
        ...props.owlCarouselItem.config,
        ...(props.owlCarouselItem.config?.responsive &&
        !props.owlCarouselItem.config?.slickConfig
            ? {
                  responsive: convertResponsive(
                      props.owlCarouselItem.config?.responsive
                  )
              }
            : {})
    })
    useEffect(() => {
        setSettings({
            ...settingsWithNoSwipe,
            ...props.owlCarouselItem.config,
            ...(props.owlCarouselItem.config?.responsive &&
            !props.owlCarouselItem.config?.slickConfig
                ? {
                      responsive: convertResponsive(
                          props.owlCarouselItem.config?.responsive
                      )
                  }
                : {})
        })
        const owlCarouselItem = props.divRef?.current?.querySelector(
            `#${props?.owlCarouselItem?.linkedDOM?.id}`
        )
        if (!owlCarouselItem?.className?.includes('hidden-owl-carousel')) {
            owlCarouselItem?.classList?.add('hidden-owl-carousel')
        }
    }, [props.owlCarouselItem])

    return (
        <div
            id={props?.owlCarouselItem?.id}
            className={cn(
                props?.owlCarouselItem?.id,
                'owl-carousel',
                props.owlCarouselItem.config?.progressBarSlider
                    ? 'pagebuilder-slider progress-bar-slider'
                    : ''
            )}
        >
            <SlickSlider {...settings}>
                {props?.owlCarouselItem?.items?.map((item, index) => (
                    <div key={index} className="item-wrapper">
                        <div className={props?.owlCarouselItem?.itemClassName}>
                            <div
                                className="item"
                                dangerouslySetInnerHTML={{
                                    __html: String(item)
                                }}
                            />
                        </div>
                    </div>
                ))}
            </SlickSlider>
        </div>
    )
}
