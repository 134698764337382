import { useQuery } from '@pylot-data/hooks/use-query'
import { WEB_FORMS_QUERY } from './WebFormsQuery'
import type { SWRConfiguration } from 'swr'
import { GraphQLError } from '@pylot-data/graphqlFetch'
import type { WebForm } from '@pylot-data/pylotschema'

type WebFormVariableType = {
    formId: number
}

type UseWebFormResult = {
    webFormsData?: WebForm
    error?: GraphQLError
}

type WebFormQueryOutput = {
    webformsForm: WebForm
}

export const useWebForm = (
    formId: number,
    swrOptions?: SWRConfiguration
): UseWebFormResult => {
    const queryData = useQuery<WebFormVariableType, WebFormQueryOutput>(
        WEB_FORMS_QUERY,
        {
            formId: formId
        },
        swrOptions
    )
    const webFormsData = queryData?.data?.data?.webformsForm

    return {
        webFormsData: webFormsData,
        error: queryData.error
    }
}
